<script lang="ts" setup>
import { useModal } from 'vue-final-modal'

import PriceOrAmountWithPromoCode from '~/components/modal-windows/PriceOrAmountWithPromoCode.vue'
import helpers from '~/helpers'

const props = withDefaults(
  defineProps<{
    discountInKopecks?: number
    mode: 'cart-product' | 'product-card' | 'product-page'
    needToRoundPriceWithPromoCode?: boolean
    productData: any
    quantity?: number
  }>(),
  { discountInKopecks: 0, needToRoundPriceWithPromoCode: true, quantity: 1 }
)

const hasPriceWithPromoCode = computed(
  () => priceWithPromoCode.value !== null && priceWithPromoCode.value !== price.value
)
const isForCartProductOrProductPage = computed(() => ['cart-product', 'product-page'].includes(props.mode))
const oldPrice = computed(() => props.productData.attributes.oldPrice)
const price = computed(() => props.productData.attributes.price)
const priceWithPromoCode = computed(() => {
  const { needToRoundPriceWithPromoCode, productData } = props
  const { priceWithPromoCode } = productData.attributes

  if (typeof priceWithPromoCode === 'number') {
    return needToRoundPriceWithPromoCode ? Math.ceil(priceWithPromoCode) : priceWithPromoCode
  }

  return null
})
const totalInKopecks = computed(() => {
  if (totalWithoutDiscountInKopecks.value === null) {
    return null
  }

  return totalWithoutDiscountInKopecks.value - props.discountInKopecks
})
const totalWithoutDiscountInKopecks = computed(() => {
  const priceInKopecks = helpers.convertRublesToKopecks(price.value)

  if (priceInKopecks === null) {
    return null
  }

  const total = props.quantity * priceInKopecks

  return isNaN(total) ? null : total
})

async function openPriceWithPromoCodeModalWindow (): Promise<void> {
  await useModal({ component: PriceOrAmountWithPromoCode, attrs: { isPrice: props.mode !== 'cart-product' } }).open()
}
</script>

<template>
  <div
    v-if="price !== null"
    class="price-area flex items-center"
    :class="{
      'has-price-with-promo-code': hasPriceWithPromoCode && discountInKopecks === 0,
      'is-for-cart-product': mode === 'cart-product',
      'is-for-product-card': mode === 'product-card',
      'is-for-product-page': mode === 'product-page'
    }"
  >
    <template v-if="hasPriceWithPromoCode && discountInKopecks === 0">
      <div>
        <span
          class="price-with-promo-code text-white"
          title="Цена с промокодом"
        >{{ helpers.formatPrice(helpers.roundToTwo(quantity * priceWithPromoCode!)) }}&nbsp;₽</span>

        <span
          v-if="isForCartProductOrProductPage"
          class="price-description whitespace-nowrap"
        >&nbsp;с&nbsp;промокодом&nbsp;<SvgIcon
          class="help-icon cursor-pointer"
          name="outlined/help-outline"
          @click="openPriceWithPromoCodeModalWindow"
        /></span>
      </div>
      <div>
        <span class="price-without-promo-code" title="Цена без промокода">
          {{ helpers.formatPrice(helpers.roundToTwo(quantity * price)) }}&nbsp;₽
        </span>
        <span v-if="isForCartProductOrProductPage" class="price-description whitespace-nowrap">без&nbsp;промокода</span>
      </div>
    </template>
    <template v-else-if="discountInKopecks">
      <span class="price" title="Цена со скидкой">{{ totalInKopecks === null ? '—' : helpers.formatPrice(totalInKopecks, true) }}</span>
      <del title="Цена без скидки">{{ totalWithoutDiscountInKopecks === null ? '—' : helpers.formatPrice(totalWithoutDiscountInKopecks, true) }}</del>
    </template>
    <template v-else>
      <span v-if="oldPrice === null" class="price" title="Розничная цена">{{ helpers.formatPrice(helpers.roundToTwo(quantity * price)) }}&nbsp;₽</span>
      <template v-else>
        <span class="price sale-price" title="Сниженная розничная цена">{{ helpers.formatPrice(helpers.roundToTwo(quantity * price)) }}&nbsp;₽</span>
        <del title="Розничная цена">{{ helpers.formatPrice(helpers.roundToTwo(quantity * oldPrice)) }}&nbsp;₽</del>
      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

.price-area {
  @media screen and (max-width: 480px) {
    align-items: flex-start;
    flex-direction: column;
  }

  &.is-for-cart-product {
    &.has-price-with-promo-code {
      align-items: flex-start;
      flex-direction: column;

      & > *:last-child {
        margin-top: 8px;
      }

      .price-without-promo-code {
        margin-left: 0;
      }
    }

    .price {
      font-size: 18px;
      line-height: 18px;
    }

    .price-description,
    .price-without-promo-code {
      font-size: 14px;
    }

    svg {
      font-size: 18px;
    }
  }

  &.is-for-product-card {
    .price {
      @media screen and (max-width: 480px) {
        font-size: 16px !important;
        line-height: 16px !important;
      }
    }
  }

  &.is-for-product-page {
    &.has-price-with-promo-code {
      align-items: flex-start;
      flex-direction: column;

      & > *:last-child {
        margin-top: 16px;
      }

      .price-without-promo-code {
        margin-left: 0;
      }
    }

    .price {
      font-size: 32px;
      line-height: 32px;
    }

    .price-description,
    .price-without-promo-code,
    svg {
      font-size: 18px;
    }

    del {
      font-size: 18px;
    }
  }
}

.price {
  color: black;

  &.sale-price {
    color: $main-green-color;
  }
}

.price-with-promo-code {
  background: $itab-gradient;
  border-radius: 4px;
  padding: 4px 8px;

  @media screen and (max-width: 480px) {
    border-radius: 2px;
    padding: 2px 4px;
  }
}

.price,
.price-with-promo-code {
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
}

.price-description {
  color: mix($main-gray-color, white, 60%);
  line-height: 1.5em;
}

svg {
  color: $main-green-color;

  &:hover {
    color: $main-blue-color;
  }
}

del,
.price-without-promo-code {
  font-size: 14px;
  line-height: 14px;
  margin-left: 16px;

  @media screen and (max-width: 480px) {
    font-size: 10px;
    line-height: 14px;
    margin-left: 0;
  }
}
</style>
